import {
  ParameterSelector,
  ParameterSelectorProps,
  prettyLabelNames,
} from "@protocol/pn.backoffice.components/build"
import { Button, Form, Input, notification } from "antd"
import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { QueryContext } from "../../api/QueryContext"
import {
  ParameterValues as ApiParameterValues,
  IReportSchedule,
  ReportSchedule,
} from "../../api/api"
import { emailOptions } from "./emailOptions"
import { Convert, IEditableSchedule, ISchedule, Parameter } from "./schedule"
import { SchedulingOptions } from "./schedulingOptions"

export const Schedule = () => {
  const [form] = Form.useForm<ISchedule | IEditableSchedule>()
  const queries = useContext(QueryContext)
  const [parameterDetails, setParameterDetails] = useState<Parameter[]>()

  const navigate = useNavigate()
  let { reportId, reportScheduleId } = useParams()

  var reportIdNumber = reportId ? parseInt(reportId) : -1
  var reportScheduleIdNumber = reportScheduleId
    ? parseInt(reportScheduleId)
    : undefined

  const report = queries.useReport(reportIdNumber, {
    enabled: reportIdNumber !== undefined,
  })

  const reportSchedule = queries.getReportSchedule(reportScheduleIdNumber!, {
    enabled: reportScheduleId !== "new",
  })

  const handleParameterChange = (
    parameterId: number,
    newValue: string | string[] | undefined
  ) => {
    setParameterDetails((currentDetails) => {
      const details = currentDetails || []
      const index = details.findIndex((x) => x.options.id === parameterId)

      if (index === -1) {
        return details
      }

      const newDetails = [...details]
      const updatedValue = Array.isArray(newValue)
        ? newValue.length > 0
          ? newValue.join(",")
          : undefined
        : newValue
      newDetails[index] = { ...newDetails[index], value: updatedValue }

      return newDetails
    })
  }

  useEffect(() => {
    if (!reportSchedule.isLoading) {
      form.setFieldsValue({
        ...Convert(reportSchedule.data),
      })
    }
  }, [reportSchedule.data])

  useEffect(() => {
    const parameters = report.data?.parameters.map((p) => {
      const parameterValue = reportSchedule.data?.parameters?.find(
        (x) => x.label?.toLowerCase() == p.name?.toLowerCase()
      )?.value

      return {
        name: p.name!,
        value: parameterValue,
        options: p,
      }
    })

    if (parameters) {
      setParameterDetails(parameters)
    }
  }, [report.data, reportSchedule.data])

  const onFinish = async (values: ISchedule | IEditableSchedule) => {
    const savedParams = parameterDetails?.map(
      (x) =>
        new ApiParameterValues({
          label: x.name,
          value:
            parameterDetails.find(
              (y) => y.name?.toLowerCase() == x.name.toLowerCase()
            )?.value ?? x.value,
        })
    )

    var payload: IReportSchedule = {
      ...values,
      daysOfTheWeek: values.daysOfTheWeek ?? [],
      id: (values as IEditableSchedule).id
        ? (values as IEditableSchedule).id
        : -1,
      timeToRun: values.timeToRun.format("HH:mm:ss"),
      emailTo: values.emailTo.join(";"),
      emailCc: values.emailCc?.join(";"),
      emailBcc: values.emailBcc?.join(";"),
      parameters: savedParams,
      everyXMinutes: undefined,
      reportId: reportIdNumber,
    }

    notification.open({
      key: payload.id.toString(),
      message: "Saving",
    })
    try {
      if (payload.id === -1) {
        await queries.postReportSchedule(payload)
      } else {
        await queries.putReportSchedule(
          payload.id,
          ReportSchedule.fromJS(payload)
        )
      }
    } catch (error) {
      notification.error({
        key: reportScheduleId,
        message: "Save Failed",
        description: <>{error}</>,
      })
      throw error
    }

    notification.success({
      key: reportScheduleId,
      message: "Saved",
    })
  }

  const handleDeleteClick = async () => {
    if (reportScheduleIdNumber) {
      await queries.api.reportSchedules_DeleteReportSchedule(
        reportScheduleIdNumber
      )
      queries.invalidateQuery("reportSchedules")
      navigate("/reports/" + reportIdNumber + "/schedules")
    }
  }

  const parameterObject = (parameter: Parameter) => {
    let sharedProps: ParameterSelectorProps = {
      parameter: parameter.options,
      getParameterData: queries.getParameterDataAsPromise,
      parentValues: undefined,
      onValueChange: handleParameterChange,
      hasInternalState: false,
    }
    let parentValues = parameter.value?.split(",") ?? []

    let childParamValues = (parameterDetails ?? []).filter(
      (cP) =>
        cP.name.toLocaleLowerCase() ===
        parameter.options.child?.name?.toLocaleLowerCase()
    )
    let firstChildParamValues =
      childParamValues.length !== 0 ? childParamValues[0] : undefined
    let firstChildParamValueArray =
      firstChildParamValues?.value?.split(",") ?? []

    return parameter.options.child !== undefined
      ? ParameterSelector({
          ...sharedProps,
          selectedValues: firstChildParamValueArray.map((value) => ({
            label: parameter.options.child!.name,
            value: value,
          })),
          parentValues: parentValues.map((c) => {
            return { label: parameter.name, value: c }
          }),
        })
      : ParameterSelector({
          ...sharedProps,
          selectedValues: parentValues.map((g) => ({
            label: parameter.options!.name,
            value: g,
          })),
        })
  }

  if (report?.data === undefined) {
    return <>Report does not exist</>
  } else if (reportSchedule.isLoading) {
    return <>Loading</>
  } else {
    return (
      <div>
        <Button key="delete" onClick={handleDeleteClick}>
          Delete Me
        </Button>
        <Form
          name="schedules"
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 8 }}
          initialValues={{ ...Convert(reportSchedule.data) }}
        >
          {!reportScheduleId ? undefined : (
            <Form.Item name="id" hidden={true}>
              <Input />
            </Form.Item>
          )}
          <Form.Item name="reportId" hidden={true}>
            <Input />
          </Form.Item>
          <h2>Schedule Options</h2>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: "Name Required" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea rows={4} />
          </Form.Item>
          {emailOptions}
          <SchedulingOptions
            initialScheduledType={reportSchedule.data?.scheduledType}
            onScheduledTypeChange={(value) => console.log("Changed to", value)}
          />

          <div>
            {parameterDetails
              ?.filter((x) => x.options.parentId === null)
              .map((parameter) => {
                return (
                  <div key={reportScheduleId + parameter.name}>
                    <label style={{ paddingRight: 10, paddingLeft: 10 }}>
                      {prettyLabelNames[parameter.name ?? ""] ?? parameter.name}
                    </label>
                    {parameterObject(parameter)}
                  </div>
                )
              })}
          </div>

          <Input type={"submit"} value={"Submit"} />
        </Form>
      </div>
    )
  }
}
