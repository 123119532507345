import { Checkbox, Form, InputNumber, Select, TimePicker } from "antd"
import { useEffect, useState } from "react"
import { DayOfWeek, ScheduledType } from "../../api/api"

interface SchedulingOptionsProps {
  initialScheduledType?: ScheduledType
  onScheduledTypeChange?: (value: ScheduledType) => void
}

export const SchedulingOptions: React.FC<SchedulingOptionsProps> = ({
  initialScheduledType,
  onScheduledTypeChange,
}) => {
  const [currentScheduledType, setCurrentScheduledType] = useState<
    ScheduledType | undefined
  >(initialScheduledType)

  useEffect(() => {
    if (initialScheduledType !== undefined) {
      setCurrentScheduledType(initialScheduledType)
    }
  }, [initialScheduledType])

  const handleScheduledTypeChange = (value: ScheduledType) => {
    setCurrentScheduledType(value)
    onScheduledTypeChange?.(value)
  }

  return (
    <>
      <h2>Scheduling Options</h2>
      <Form.Item
        label="When to run"
        name="scheduledType"
        tooltip={
          "You can either run once a month or on certain days of the week"
        }
      >
        <Select<ScheduledType>
          id="ScheduleTypeSelect"
          style={{ width: "500px" }}
          onChange={handleScheduledTypeChange}
          value={currentScheduledType}
        >
          <Select.Option value={ScheduledType.DayOfWeek}>Week</Select.Option>
          <Select.Option value={ScheduledType.DayOfMonth}>Month</Select.Option>
        </Select>
      </Form.Item>

      {currentScheduledType === ScheduledType.DayOfWeek && (
        <Form.Item label="Days of Week" name="daysOfTheWeek">
          <Checkbox.Group>
            <Checkbox value={DayOfWeek.Monday}>Monday</Checkbox>
            <Checkbox value={DayOfWeek.Tuesday}>Tuesday</Checkbox>
            <Checkbox value={DayOfWeek.Wednesday}>Wednesday</Checkbox>
            <Checkbox value={DayOfWeek.Thursday}>Thursday</Checkbox>
            <Checkbox value={DayOfWeek.Friday}>Friday</Checkbox>
            <Checkbox value={DayOfWeek.Saturday}>Saturday</Checkbox>
            <Checkbox value={DayOfWeek.Sunday}>Sunday</Checkbox>
          </Checkbox.Group>
        </Form.Item>
      )}

      {currentScheduledType === ScheduledType.DayOfMonth && (
        <Form.Item label="Day of Month" name="dayOfTheMonth">
          <InputNumber />
        </Form.Item>
      )}

      <Form.Item label="Time" name="timeToRun">
        <TimePicker showSecond={false} minuteStep={5} />
      </Form.Item>
    </>
  )
}
